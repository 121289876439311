/****************** General ************************/
/***************************************************/
/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#services_module.list .list_item .footer,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: #cee2ee !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top-color: #cee2ee;
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid #cee2ee;
}

/* dates */
#alerts_module.list .title .date,
#events_module.cms_list .cms_date,
#feeds_module.cms_list .cms_list_item .cms_date h4,
#feeds_module.cms_entity .cms_date h4,
#jobs_module.cms_list .cms_list_item .cms_date h3,
#news_module.cms_list article time,
#news_module.cms_entity .cms_date h3,
#photoalbums_module.cms_list .cms_list_item .cms_number_of_images, /* this is not a date but styled as one */
.posts-node .posts-field.posts-field-posted p,
#video_module.cms_list .cms_list_item .cms_date,
#video_module.cms_entity .cms_date h3 {
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	color: #222222;
	font-family: 'Open Sans', sans-serif;
}

/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: none;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #3169b4;
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ff0000;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

/****************** Accounts ************************/
/***************************************************/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 300px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #cee2ee;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #3169b4;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
	font-family: 'Open Sans', sans-serif;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ff0000;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}


/****************** Events *********************/
/***********************************************/

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}


/****************** FAQs *********************/
/***********************************************/
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}


/****************** News ***********************/
/***********************************************/
#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
	}
}

/***************** Resources *******************/
/***********************************************/

/*************** Submission Forms ***************/
/***********************************************/
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .ui-datepicker-trigger {
	left: 5px;
	top: 5px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

/*form with payment*/
#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 27%;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
}

#submissionforms_module.cms_form #form-payment-info .s-payment-method-label {
	display: inline;
}

#submissionforms_module.cms_form #form-payment-info #payment-info .ui-form-panel {
	padding-top: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-fieldset:not(#payment-info) .ui-form-panel {
	padding-bottom: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-expiration-date-fields > div:first-of-type {
	margin-bottom: 10px;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-text-phone-extension {
	margin-top: 10px;
	margin-left: 0;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/*confirmation page*/
.module-forms.confirmation .step-title {
	margin-top: 1rem;
	color: #3169b4;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 35%;
		left: 5px;
	}
}

/**************** Testimonials ******************/
/***********************************************/
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_content .testimonial-link {
	border-bottom: 0px;
}

/****************** Content Boxes *******************/
/***************************************************/
/* events */
#events_module.homepage_contentbox .event_list {
	margin-bottom: 12px;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 100px;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 100px);
}

.slider-widget #events_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
}

@media (max-width: 991px) {
	#events_module.homepage_contentbox .event_list table tr td.date,
	#events_module.homepage_contentbox .event_list table tr td.title {
		display: block;
		width: 100%;
	}
}

/* files */
.cb-boxes .files-collection .files-node {
	padding: 0px;
	margin: 0px 0px 7px 0px;
}

.slider-widget .files-collection {
	text-align: left;
	padding: 20px;
}

/* news  */
#news_module.homepage_contentbox .cms_title h3 {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

#news_module.homepage_contentbox .cms_date h3 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	color: #474848;
	font-size: 0.8rem;
	line-height: 1.1rem;
	font-weight: 400;
}

#news_module.homepage_contentbox .cms_description p {
	margin-bottom: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

#news_module.homepage_contentbox > a {
	display: none;
}

#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 20px;
}

.slider-widget #news_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
}

/* testimonials */
#testimonials_module_contentbox.contentbox_item .company,
#testimonials_module_contentbox.contentbox_item .author-name {
	font-weight: bold;
}

#testimonials_module_contentbox .w-slide-testimonial {
	height: auto;
	margin-bottom: 30px;
}

.slider-widget #testimonials_module_contentbox.contentbox_item {
	text-align: left;
	margin: 20px;
}

/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	padding: 10px;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox input.hasDatepicker[type="text"] {
	width: calc(100% - 40px);
	float: left;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	left: 0;
	margin-left: 4px;
	margin-top: 0;
	position: relative;
	top: 8px;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	color: inherit;
}
